html,
body {
  font-family: $theme-font-family !important;
}

header {
  width: 100%;
}

/* custom font-size */

.fs {
  &-h1 {
    font-size: 36px !important;
    font-weight: 200 !important;

    &-bold {
      font-size: 36px !important;
      font-family: $theme-font-family !important;
      font-weight: 600 !important;
    }
  }

  &-h2 {
    font-size: 32px !important;
    font-weight: 200 !important;

    &-bold {
      font-size: 32px !important;
      font-family: $theme-font-family !important;
      font-weight: 600 !important;
    }
  }

  &-h3 {
    font-size: 26px !important;
    font-weight: 200 !important;

    &-bold {
      font-size: 26px !important;
      font-family: $theme-font-family !important;
      font-weight: 600 !important;
    }
  }

  &-h4 {
    font-size: 24px !important;
    font-weight: 200 !important;

    &-bold {
      font-size: 24px !important;
      font-family: $theme-font-family !important;
      font-weight: 600 !important;
    }
  }

  &-h5 {
    font-size: 20px !important;
    font-weight: 200 !important;
    line-height: 1.4em !important;

    &-bold {
      font-size: 20px !important;
      font-family: $theme-font-family !important;
      font-weight: 600 !important;
    }
  }

  &-h6 {
    font-size: 16px !important;
    font-weight: 200 !important;

    &-bold {
      font-size: 16px !important;
      font-family: $theme-font-family !important;
      font-weight: 600 !important;
    }
  }

  &-p {
    font-size: 14px !important;
    font-weight: 200 !important;

    &-bold {
      font-size: 14px !important;
      font-family: $theme-font-family !important;
      font-weight: 600 !important;
    }
  }

  &-title {
    font-size: 36px !important;
    font-weight: 200 !important;

    &-bold {
      font-size: 36px !important;
      font-family: $theme-font-family !important;
      font-weight: 600 !important;
    }
  }

  &-label {
    font-size: 14px !important;
    font-weight: 200 !important;

    &-bold {
      font-size: 14px !important;
      font-family: $theme-font-family !important;
      font-weight: 600 !important;
    }
  }

  &-label-sm {
    font-size: 10px !important;
    font-weight: 200 !important;

    &-bold {
      font-size: 10px !important;
      font-family: $theme-font-family !important;
      font-weight: 600 !important;
    }
  }
}

/* Text Color */
.cl {
  &-text {
    color: $theme-font-color;
  }

  &-subtext {
    color: $theme-font-color-light;
  }

  &-navy {
    // color: $navy-color;
    color: $blue-dark-color;
  }

  &-blue {
    color: $blue-color;
  }

  &-website {
    color: #00a850;
  }

  &-light {
    color: $light-color;
  }

  &-yellow {
    color: $warning-color;
  }

  &-danger {
    color: $danger-color;
  }

  &-success {
    color: $success-color;
  }

  &-warning {
    color: $warning-color;
  }

  &-white {
    color: $white-color;
  }
}

/* Button */
.btn {
  &-blue {
    background-color: $blue-color;
    color: $light-color;
    border: none;

    &:hover {
      background-color: darken($blue-color, 10%);
      color: $light-color;
    }
  }

  &-success {
    background-color: $success-color;
    color: $light-color;
    border: none;

    &:hover {
      background-color: darken($success-color, 10%);
      color: $light-color;
    }
  }

  &-warning {
    background-color: $warning-color;
    color: $light-color;
    border: none;

    &:hover {
      background-color: darken($warning-color, 10%);
      color: $light-color;
    }
  }

  &-light {
    background-color: $white-color;
    color: $menu-light-text-color;

    &.active {
      background-color: darken($btn-gray-active, 10%);
      color: $menu-light-text-color;
    }

    &:hover {
      background-color: darken($white-color, 10%);
      color: $menu-light-text-color;
    }
  }

  &-gray {
    background-color: $gray-color;
    color: $menu-light-text-color;
    border: none;

    &.active {
      background-color: darken($btn-gray-active, 10%);
      color: $menu-light-text-color;
    }

    &:hover {
      background-color: darken($gray-color, 10%);
      color: $menu-light-text-color;
    }
  }

  &-tabs {
    background-color: #f5f7fb;
    color: $menu-light-text-color;
    border: 1px solid #efefef;

    &.active {
      background-color: darken($btn-gray-active, 10%);
      color: $menu-light-text-color;
      border: none;
    }

    &:hover {
      background-color: darken(#f5f7fb, 10%);
      color: $menu-light-text-color;
    }
  }

  &-navy {
    background-color: $navy-color;
    color: $white-color;
    border: none;

    &:hover {
      background-color: darken($navy-color, 10%);
      color: $white-color;
    }
  }

  &-none-bg {
    background-color: $theme-background;
    color: $default-color;

    &:hover {
      background-color: darken($light-color, 10%);
      color: $gray-color;
    }
  }

  &-clear {
    background-color: none;
    color: $theme-font-color;
  }

  &-search {
    height: 100%;
    padding: 10px 15px;
    background-color: #edf0f5;
    border: 1px solid #ced4da;

    i {
      margin: 0 auto;
    }
  }
}

/* Background Color*/
.bgc {
  &-light {
    background-color: $white-color;
  }

  &-gray-light {
    background-color: #efefef;
  }

  &-active {
    background-color: $btn-gray-active;

    &:hover {
      background-color: darken($btn-gray-active, 10%);
    }
  }

  &-danger {
    background-color: $danger-color;
  }

  &-success {
    background-color: $success-color;
  }

  &-warning {
    background-color: $warning-color;
  }

  &-navy {
    background-color: $navy-color;
  }
}

/* Input Form */
.form {
  &-height {
    height: 40px !important;
  }
}

.cus-check-input {
  width: 25px;
  height: 25px;
}

.form-switch .form-check-input:checked {
  border: none;
  background-color: $success-color;
}

/* Navbar */
.navbar-background {
  background-color: $header-dark-background;
}

/* WRAPPER */
.wrap-subtitle {
  padding: 0px 30px 0px 30px;
  background-color: $header-light-background;
}

.wrap-subtitle p {
  margin: 0;
}

.wrap-body {
  padding: 20px;
  margin-bottom: 60px;
}

/* Table: ngx-datatable */
.ngx-datatable.bootstrap {
  .datatable-header {
    background-color: #e9edf5;

    &-cell-label {
      color: $navy-color;
      font-weight: bold;
    }
  }

  .datatable-body {
    &-row.datatable-row-even {
      background-color: $white-color !important;
      border-top: 1px solid #e9edf5 !important;
    }

    &-row.datatable-row-odd {
      background-color: #f6f7f9 !important;
      border-top: 1px solid #e9edf5 !important;
    }
  }

  .datatable-footer {
    background-color: #e9edf5;

    &-inner {
      color: $navy-color;
    }

    .datatable-pager {
      a {
        color: $navy-color;
      }

      ul li:not(.disabled).active a,
      ul li:not(.disabled):hover a {
        background-color: $white-color;
      }
    }
  }
}

.cellColumn {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;

  &.end {
    justify-content: end;
  }

  &.tagNavy {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $navy-color;
    }
  }

  &.tagBlue {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $blue-color;
    }
  }

  &.tagGreen {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #00a850;
    }
  }
}

/* Side Menu */
.pcoded-navbar .pcoded-inner-navbar app-nav-item>li.nav-item.active:after {
  display: none;
}

.pcoded-navbar .pcoded-inner-navbar>app-nav-group>app-nav-collapse>li.pcoded-trigger:after {
  display: none;
}

.pcoded-navbar .pcoded-inner-navbar app-nav-item>li.nav-item.active>a {
  background-color: $menu-active-color;
}

.cursor-pointer {
  cursor: pointer;
}

.img {
  &-w-40 {
    width: 40px;
    height: auto;
  }

  &-w-30 {
    width: 30px;
    height: auto;
  }
}

//Datatable
.ngx-datatable.material {
  background: #fff;
  box-shadow: none;
}

//background-container
.container-bg {
  background-color: #f5f5f5;
}

.fa-facebook {
  color: #2B4498;
}

.fa-twitter {
  color: #4DB1F0;
}

.fa-globe {
  color: #0fb36c;
}

// Progress bar label
.label-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;

  .label-info {
    font-size: 10px;
    margin: 0;
  }

  i {
    font-size: 10px;
  }
}

/* Responsive Style */

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {}

/* === Style Before Update === */
.form-control {
  height: auto;
}

.border-less-image-gallery ks-modal-gallery .plain-container .image {
  border: none;
}

.h-back-user-list {
  color: $primary-color !important;
}

.fa-house {
  color: $primary-color !important;
}

@media only screen and (max-width: 575px) {
  .pcoded-header .dropdown .dropdown-menu {
    left: 0 !important;
  }

  wizard.vertical .vertical a {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .filter-bar .card-task .task-board {
    float: none;
    margin-top: 25px;
  }
}

.pcoded-header .dropdown.show:before {
  left: -10px !important;
}

div.horizontal-scroll {
  overflow: auto;
  white-space: nowrap;
}

#timer {
  color: #ffffff;

  b {
    font-size: 24px;
  }
}

pre code {
  width: auto;
  min-width: 100%;
  margin-left: -80px !important;
}

.btn.btn-sm {
  padding: 3px 12px;
  border-radius: 0.15rem;
}

.btn.btn-lg {
  padding: 12px 25px;
  border-radius: 0.35rem;
}

.fixed-button {
  position: fixed;
  bottom: -50px;
  right: 30px;
  -webkit-box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
  opacity: 0;
  z-index: 9;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;

  .btn {
    margin-bottom: 0;
    margin-right: 0;
  }
}

.fixed-button.active {
  bottom: 50px;
  opacity: 1;
}