// =======================================
//     List of variables for layout
// =======================================

$header-height: 70px;
$Menu-width: 264px;
$Menu-collapsed-width: 80px;

// color list for build layouts
// temp variables
$theme-color: linear-gradient(-135deg, #0fb36c 0%, #1dc4e9 100%);
$theme-color2: linear-gradient(-135deg, #899fd4 0%, #a389d4 100%);
$primary-color: #2b4498;
$warning-color: #fcb034;
$danger-color: #f44236;
$success-color: #0fb36c;
$purple-color: #a389d4;
$info-color: #3ebfea;
$secondary-color: #748892;
$default-color: #d6d6d6;
$light-color: #f2f2f2;
$dark-color: #37474f;
$inverse-color: $dark-color;
$theme-border: #eaeaea;
$blue-color: #0d6efd;
$blue-dark-color: #0345a5;
$navy-color: #122f56;
$gray-color: #edf0f5;
$gray2-color: #f7f8fa;
$white-color: #ffffff;
$facebook-color: #2b4498;
$twitter-color: #4db1f0;
$website-color: #0fb36c;

// Button
$btn-gray-active: #cfe2ff;

// header
$brand-background: #2b4498;
$header-dark-background: #2b4498;
$header-dark-text-color: #fff;
$header-light-background: #fff;
$header-light-text-color: #2b4498;

// Menu
$menu-caption-color: #14171d;
$menu-dark-background: #2f313e;
$menu-dark-text-color: #a9b7d0;
$menu-light-background: #fff;
$menu-light-text-color: #2f313e;
$menu-active-color: #fcb034;
$login-active-color: #1b5e8e;

// dark layout colors
$dark-layout: #212224;
$dark-layout-font: #adb7be;

// Menu icon
$menu-icon-color: $primary-color, #ff5252, #01a9ac, #9575cd, #23b7e5,
  $warning-color;
// Header background
$color-header-name: blue, red, purple, lightblue, dark;
$color-header-color: $primary-color, #ff5252, #9575cd, #23b7e5,
  lighten($dark-layout, 7%);
// Menu background
$color-menu-name: blue, red, purple, lightblue, dark;
$color-menu-color: $primary-color, #ff5252, #9575cd, #23b7e5,
  lighten($dark-layout, 7%);
// Active background color
$color-active-name: blue, red, purple, lightblue, dark;
$color-active-color: $primary-color, #ff5252, #9575cd, #23b7e5,
  lighten($dark-layout, 7%);
// Menu title color
$color-title-name: blue, red, purple, lightblue, dark;
$color-title-color: $primary-color, #ff5252, #9575cd, #23b7e5,
  lighten($dark-layout, 7%);

$theme-font-family: "Kanit", sans-serif;
$theme-font-size: 14px;
$theme-font-size-28: 28px;
$theme-background: #f4f7fa;
$theme-font-color: #454545;
$theme-font-color-light: #999999;
$theme-heading-color: #111;

// Font
$font-family-regular: "Kanit";
$font-family-medium: "Kanit-Medium";
$font-family-bold: "Kanit-Bold";

$layout-6-bg-color: #23b7e5;

$color-name: c-blue, c-red, c-green, c-yellow, c-purple;
$color-color: $primary-color, $danger-color, $success-color, $warning-color,
  $purple-color;
$color-bt-name: primary, danger, success, warning, info;
$color-bt-color: $primary-color, $danger-color, $success-color, $warning-color,
  $info-color; // form-control variables
$form-bg: #f0f3f6;

$theme-colors: () !default;

$theme-colors: map-merge(
  (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "success": $success-color,
    "info": $info-color,
    "warning": $warning-color,
    "danger": $danger-color,
    "light": $light-color,
    "dark": $dark-color,
  ),
  $theme-colors
); // Color contrast
$yiq-contrasted-threshold: 200 !default;
$yiq-dark-color: #37474f;
$yiq-light-color: #fff;
$theme-color-interval: 8% !default;
$alert-bg-level: -10 !default;
$alert-border-level: -9 !default;
$alert-color-level: 6 !default;

$soft-bg-level: -80%;
