/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "node_modules/@swimlane/ngx-datatable/themes/bootstrap.css";
@import "node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "node_modules/@swimlane/ngx-datatable/index.css";

@import "scss/variables";

/* fonts-icon */
@import "scss/fonts/fontawesome/scss/fontawesome";
@import "scss/fonts/feather/iconfont";

@import "scss/general";
@import "scss/generic";
@import "scss/mixins/function";

/* important element */
@import "scss/menu/menu-lite";

/* basic elements */
@import "scss/theme-elements/theme-elements";

/* third party modules style*/
@import "scss/plugins/plugins";

/* Custom Style */
@import "scss/custom";
@import "scss/font";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";


@import "~@ng-select/ng-select/themes/default.theme.css";

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
* {
  font-family: "Kanit", sans-serif;
}

้html,
body {
  font-family: "Kanit", sans-serif;
}

//ฟอนด์ Swal alret
h2#swal2-title.swal2-title {
  font-family: "Kanit", sans-serif;
}

.swal2-container {
  z-index: 9999 !important;
}

.spinner-shown {
  overflow: hidden !important;
}

//แก้ไข Layout ของ mat-select
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 9999 !important;
}

.btn-reset {
  background-color: #e2e2e2;
}

.footer {


  .font-sub {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #fcb034;
  }

  .font-tel {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #454545;
  }

  .font-ser {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 0px;
    display: flex;
    align-items: center;
    color: #454545;
  }
}
