@font-face {
    font-family: "Kanit";
    src: url('../assets/fonts/Kanit/Kanit-Regular.ttf');
}

@font-face {
    font-family: "Kanit-Medium";
    src: url('../assets/fonts/Kanit/Kanit-Medium.ttf');
}

@font-face {
    font-family: "Kanit-Bold";
    src: url('../assets/fonts/Kanit/Kanit-Bold.ttf');
}